<template>
  <div class="" v-if="pluginName != ''">
    <appintegraplugin :pluginName="pluginName" :key="refresh" :props="{ fruta: 'maçã', verdura: 'alface' }"/>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import appintegraplugin from "@/components/plugins2/app-integra-plugin.vue";

export default {
  name: "router",
  components: {
    appintegraplugin
  },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      pluginName: "",
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
      var tpIdx = this.$route.params.id;
      tpIdx = tpIdx.replaceAll("@","/");
      console.log("tpIdx",tpIdx);
      if(tpIdx != undefined) {
        self.pluginName = tpIdx;
        self.refresh++;
      }

    }
  }
}
</script>

<style scoped>
</style>
